import React, { useEffect } from "react"
import HeadSubhead from "../../microcomponents/headsubhead/headsubhead"
import Container from "../../microcomponents/container/container"
import "./frequentlyaskedquestions.scss"
import { MinusIcon, PlusIcon } from "../../microcomponents/icons"
import { navigate } from "gatsby"
import Button from "../../microcomponents/button/button"
import ReactHtmlParser from "react-html-parser"
export default function FrequentlyAskedQuestions({ version = "v2", faqs }) {
  return (
    <Container className={`frequenylyaskedquestions-wrapper ${version}`}>
      <div className="frequenylyaskedquestions-inner pd-112">
        <HeadSubhead
          version={version}
          heading="Frequently Asked Questions"
          subheading={``}
        />
        <div className="faqs-wrapper-frame">
          <div>
            {faqs?.slice(0, 4)?.map((faq, index) => (
              <div key={index}>
                <details open={index === 0 ? true : false}>
                  <summary>
                    <MinusIcon className="minus" />
                    <PlusIcon className="plus" /> {faq?.title}
                  </summary>
                  <div>{ReactHtmlParser(faq?.desc)}</div>
                </details>
              </div>
            ))}
          </div>
          <div>
            {faqs?.slice(4, 8)?.map((faq, index) => (
              <div key={index}>
                <details>
                  <summary>
                    <MinusIcon className="minus" />
                    <PlusIcon className="plus" /> {faq?.title}
                  </summary>
                  <div>{ReactHtmlParser(faq?.desc)}</div>
                </details>
              </div>
            ))}
          </div>
        </div>
        <div className="faqs-cta">
          <Button
            version={version}
            onClick={e => {
              const params = new URLSearchParams(window.location.search)
              let url = `/get-demo/`
              if (params.size > 0) {
                url = `${url}?${params.toString()}`
              }
              navigate(url)
            }}
            icon={null}
          >
            Get a demo
          </Button>
        </div>
      </div>
    </Container>
  )
}
